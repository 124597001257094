.logo {
    display : inline-block;
    border-radius: 8px;
    padding: 8px;
}

a.logo:hover {
    cursor: pointer;
    transition: background-color 0.4s;
    background-color: rgba(255, 255, 255, 0.2);
}

.logo .t1 {
    display: block;
    color: var(--semaphore-green);
    font-size: 1.2em;
    text-transform: uppercase;
}

.logo .t2 {
    display: block;
    color: var(--white);
    font-size: 1.5em;
}
