.paginator-label {
    margin-bottom: 4px;
    font-size: 14px;
}

.page-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 8px;
}

.no-total-paginator-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: center;
    align-items: flex-end;
}
