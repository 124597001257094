.navbar {
    border-bottom: 1px solid var(--surface-d);
}

.navbar-content {
    display: flex;
    max-width: 1200px;
    padding: 8px;
    margin: auto;
}

.navbar-content h3 {
    margin-right: 40px;
}