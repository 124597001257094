.dialog-wrap {
    height: 100vh;
    width: 100%;
    max-height: 100% !important;
}

.dialog-wrap .p-dialog-content {
    padding: 2px !important;
}

.dialog-wrap .section-group {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.dialog-wrap .video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 3;
}

.dialog-wrap .info {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    padding: 4px 16px;
    overflow-y: auto;
    overflow-x: hidden;
}

.dialog-wrap #video-player {
    width: 100%;
    height: auto;
}

.dialog-wrap .info div:last-child {
    min-height: 0px;
    flex-grow: 1;
}

.dialog-wrap .info p {
    text-align: justify;
}

.dialog-wrap .info img {
    max-height: 100%;
    width: 100%;
}

.dialog-wrap .button-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}